import logo_light from '../assets/images/logo-light.png';
import logo_colismi from '../assets/images/colismi_logo_small.png';
import React, { useState, useEffect } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';

export default function Navbar() {
    const [isOpen, setMenu] = useState(true);
    const [displayCompte, setDisplayCompte] = useState(true);
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    const pseudo = localStorage.getItem("pseudo");

    window.addEventListener("scroll", windowScroll);
    useEffect(() => {
        let token = localStorage.getItem("token");
        console.log(token);
        if (token == undefined ){
            setDisplayCompte(false);
        }


       // getMe();

    }, []);

    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("is-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("is-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton !== null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }
    const toggleMenu = () => {
        setMenu(!isOpen);
    }

    function setMenuOpen(){
        setMenu(!isOpen);
    }

    function stringToColor(string) {
        let hash = 0;
        let i;
      
        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
          hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }
      
        let color = '#';
      
        for (i = 0; i < 3; i += 1) {
          const value = (hash >> (i * 8)) & 0xff;
          color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */
      
        return color;
      }
      
      function stringAvatar(name) {
        return {
          sx: {
            bgcolor: stringToColor(name),
            width: '80%',
            height: '80%',
            fontFamily: 'Arial, sans-serif', // Use a more elegant font
            fontSize: '24px', 
            textTransform:'uppercase'
            
          },
          children: `${name[0]}`,
        };
      }
      

    return (
        <div>
            <nav className="navbar" id="navbar">
                <div className="container flex flex-wrap items-center justify-end">
                    <a className="navbar-brand md:me-8" href="/index">

                        < span className="inline-block dark:hidden">
                            <img src={logo_colismi} className="l-dark" alt="" />
                            <img src={logo_colismi} className="l-light logo" alt="" />
                        </span>
                        <img src={logo_light} className="hidden dark:inline-block" alt="" />
                    </a>

                    <div className="nav-icons flex items-center lg_992:order-2 ms-auto lg:ms-4">
                        <ul className="list-none menu-social mb-0">
                            <li className="inline" style={{display: 'none'}}>
                                {!displayCompte && 
                                 <Link to="/login" onClick={toggleMenu}
                                    className="h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white">
                                        
                                        <i className="uil uil-user"></i></Link>
                                }
                                { (displayCompte === true && pseudo != undefined) &&
                                    <Avatar id='menu-avatar' {...stringAvatar(pseudo)}/>
                                }

                            </li>
                        </ul>

                        <button data-collapse="menu-collapse" type="button"
                            onClick={setMenuOpen}

                            className=" btn collapse-btn inline-flex items-center ms-3 text-slate-950 dark:text-white lg_992:hidden"
                            aria-controls="menu-collapse" aria-expanded="false">
                            <span className="sr-only">Navigation Menu</span>
                            <i className="mdi mdi-menu text-[24px]"></i>
                        </button>
                    </div>
                    <div className={`${isOpen === true ? 'navigation lg_992:order-1 lg_992:flex  ms-auto hidden' : 'navigation lg_992:order-1 lg_992:flex  ms-auto block'}`} id="menu-collapse">

                        <ul className="navbar-nav nav-light sidebar-nav" id="navbar-navlist">
                            <li className={`nav-item navbar-item ${splitLocation[1] === '' ? 'active' : ''}`}>
                                <Link to="/index" className="nav-link ">Accueil</Link>
                            </li>
                            <li >
                                <Link to="/travel-search" className="nav-link">Transporteurs</Link>
                            </li>
                            <li >
                                <Link to="/parcels" className="nav-link">Demandes</Link>
                            </li>
                            <li >
                                <Link to="/pricing" className="nav-link">Abonnement</Link>
                            </li>
                            { displayCompte === true && 
                                <li >
                                    <Link to="/chat" className="nav-link">Messagerie</Link>
                                </li>
                            }
                            { displayCompte === true && 
                                <li >
                                    <Link to="/compte" className="nav-link">Compte</Link>
                                </li>
                            }
                        </ul>

                    </div>
                </div>
            </nav >
        </div>
    )
}


