import './App.css';
import React, { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AboutUs from './main/aboutus';
import './assets/css/tailwind.css'
import './assets/libs/@mdi/font/css/materialdesignicons.min.css'
import './assets/libs/@iconscout/unicons/css/line.css'
import './assets/libs/tobii/css/tobii.min.css'
import './assets/images/favicon.ico'
import Blogs from './main/blogs';
import ContactUs from './main/contactus';
import Index from './main';
import Login from './main/login';
import ResetPassword from './main/reset-password';
import VerifyCode from './main/v1/verify-code';
import VerifyEmail from './main/v1/verify-email';
import Services from './main/services';
import Signup from './main/signup';
import Switcher from './component/Switcher';
import ScrollToTop from './component/Scroll-top';
import ParcelSearch from './main/v1/parcels/parcels';
import ColisRequest from './main/v1/colis-request/colis-request';
import MonCompte from './main/v1/mon-compte/mon-compte';
import CGU from './main/v1/cgu';
import UserTrajet from './main/v1/user-trajet/user-trajet';
import DetailTravel from './main/v1/detail-trajet/detail-trajet';
import CommentsDemande from './main/v1/comments-demande/comments-demande';
import CommentsTravel from './main/v1/comments-travel/comments-travel';
import TravelSearch from './main/v1/travel-search/travel-search';
import AddTravel from './main/v1/add-trajet/add-trajet';
import UserProfilV2 from './main/v2/user-profil/user-profil';
import UserReview from './main/v1/user-review/user-review';
import AddReview from './main/v1/add-review/add-review';
import ChatPage from './main/v2/chat/chat';
import MLearning from './main/v2/mlearning/mlearning';
import Success from './main/v2/success/success';
import Pricing from './main/v2/pricing/pricing';
import Checkout from './main/v2/checkout/checkout';
import Payment from './main/v2/payment/payment';
import CheckoutOffline from './main/v2/checkout-offline/checkout-offline';
import Dashboard from './main/v2/dashboard/dashboard';
import ConfirmationSouscrition from './main/v2/confirmation-souscription/confirmation-souscription';

export default function App() {


  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add('light');
  }, []);

  return (
    <BrowserRouter >
      <ScrollToTop />
      <Routes>
        <Route path="/learning" element={<MLearning />} />
        <Route path="/cgu" element={<CGU />} />
        <Route path="/compte" element={<MonCompte />} />
        <Route path="/reservation/:numero?" element={<CommentsTravel />} />
        <Route path="/comments-demande" element={<CommentsDemande />} />
        <Route path="/traveler" element={<UserProfilV2 />} />
        <Route path="/user-trajet/:numero?" element={<UserTrajet />} />
        <Route path="/trajet/:numero?" element={<DetailTravel />} />
        <Route path="/travel-search" element={<TravelSearch />} />
        <Route path="/travel-add" element={<AddTravel />} />
        <Route path="/parcels" element={<ParcelSearch />} />
        <Route path="/colis-request" element={<ColisRequest />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/index" element={<Index />} />
        <Route path="/index-four" element={<UserProfilV2 />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/verify-code" element={<VerifyCode />} />
        <Route path="/verify/:token" element={<VerifyEmail />} />
        <Route path="/services" element={<Services />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/user-review" element={<UserReview />} />
        <Route path="/add-review" element={<AddReview />} />
        <Route path="/chat" element={<ChatPage />} />
        <Route path="/success" element={<Success />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/checkout-offline" element={<CheckoutOffline />} />
        <Route path="/confirmation-souscription" element={<ConfirmationSouscrition />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/dashboard" element={<Dashboard />} />

        <Route path="/" element={<Index />} />

      </Routes>
      <Switcher />
    </BrowserRouter>
  )
}


