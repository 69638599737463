/* eslint-disable react/no-unescaped-entities */
import React, {useEffect, useState} from 'react'
import styles from './confirmation-souscription.css';
import Navbar from '../../../component/navbar.js';
import felicitations from '../../../assets/images/felicitations.png';
import { Link } from "react-router-dom";
import Footer from '../../../component/footer.js';
import { useLocation } from "react-router-dom";

export default function ConfirmationSouscrition() {
    const location = useLocation();
    //const [message, setMessage] = useState(undefined);
    const {  message } = location.state || {}; 
    const [facture, setFacture] = useState(undefined);

    
        const downloadFile = async () => {
          const token = localStorage.getItem("token") ;// Replace with your actual token logic
          const filename = "facture_souscription.pdf";
      
          try {
            const response = await fetch(
              "https://backend-dev.colismi.com/download/invoice/facture_"+facture.numero+".pdf",
              {
                method: "GET",
                headers: {
                  Authorization: `Bearer ${token}`, // Include the token in headers
                },
              }
            );
      
            if (!response.ok) {
              throw new Error("Failed to download file");
            }
      
            const blob = await response.blob(); // Convert response to Blob
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", filename); // Set download attribute
            document.body.appendChild(link);
            link.click(); // Trigger download
            link.parentNode.removeChild(link); // Cleanup
          } catch (error) {
            console.error("Error downloading the file:", error);
          }
        };

    

    useEffect(()=>{
        console.log(location.state);
        setFacture(location.state.facture);
    }, []);
    return (
        <div>
            <Navbar/>
        <div className={styles}>
            
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-6.jpg')]" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

               

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="font-medium leading-normal text-3xl mt-10 text-white hidden">Félicitations !</h6>
                    </div>
                </div>
            </section>
            <section className="relative" id="about">
                <div>
                    <h1 className='confirmation-msg hidden'>{message}</h1>
                </div>
                <div className="lg:col-span-7 md:col-span-6 hidden">
                            <img src={felicitations} alt="" />
                        </div>
                <div className="container mx-auto">
                    <div className="items-center">
                        
                        
                    
                        <div >
                        <div className="card-success">
                            <div id='checkmark-div'>
                                <i className="checkmark">✓</i>
                            </div>
                            <div style={{textAlign: 'center', width: "90%"}}> 
                                <h1 className='msg-success' >Succès</h1> 
                                <p className='details-message'>{message}</p>
                            </div>
                            </div>

                        </div>

                    </div>

                   
                </div>
               
            </section>

            <section className="relative">
                <div className="container mx-auto text-center">
                    {facture &&
                        <button className="view-route-btn" onClick={downloadFile}>
                            <a >Télécharger le reçu</a>
                        </button>
                    }
                </div>
            </section>

            <section className='relative'>
                <div className='partners-div'>
                    <div>
                            <p>Vous pouvez effectuer les paiements dans une agence locale</p>
                            <strong>Burkina Faso</strong>
                            <br/>
                                <h1 className='title-agencies'>Nos agences partenaires</h1>

                                    <ul className="agency-list">
                                        <li className="agency-item">
                                            <h2>Patience Plus: <strong> +226 70 18 97 64  </strong></h2>
                                            <p>Situé à Larlé près de la perle</p>
                                            <p>Ouagadougou , Burkina Faso</p>
                                        </li>
                                        <li className="agency-item">
                                            <h2>Justi Shop:  <strong> +226 74 48 19 19  </strong></h2>
                                            <p>Situé non loin du Laboratoire national de santé publique</p>
                                            <p>Ouagadougou: Burkina Faso</p>
                                        </li>
                                    </ul>
                    </div>
                    </div>

                    <div>
                            <div className="action-buttons">
                                    <button  className="view-route-btn"><Link to={'/compte/'} > Mon compte</Link></button>
                                    <button  className="interested-btn"><Link to={'/'} >Retour à l'accueil</Link></button>
                            </div>
                        </div>

            </section>
        </div>
        <Footer/>
        </div>
    )
}