/* eslint-disable react/no-unescaped-entities */
import contact from '../../../assets/images/colismi/trajet/colis_request.svg'
import React, { useEffect, useState } from 'react';
import Navbar from '../../../component/navbar';
import Footer from '../../../component/footer';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { useNavigate } from "react-router-dom";

export default function ColisRequest() {
    const backend_url = process.env.REACT_APP_BACKEND_URL;
    const navigate = useNavigate();
    const [deactivateButton, setDeactivateButton] = useState(true);
    const [villes, setVilles] = useState([]);
    const [choixVisibilite, setChoixVisibilite] = useState("Public");
    const [isLoggedin, setIsLoggedin] = useState(false);
    const [objetDemande, setObjetDemande] = useState("Information");
    const options = [
        { value: 'Public', label: 'Tout le monde' },
        { value: 'Professionnel', label: 'Professionels uniquement' },
      ]

    const options_type_demande = [
        { value: 'Information', label: "Demande d'information" },
        { value: 'Achat', label: "Achat d'articles" },
        { value: 'Envoi', label: 'Envoi de colis' },
        { value: 'Coursier', label: 'Je recherche un coursier' }
    ]
    function changeObjetDemande(item){
        setObjetDemande(item.value);
    }
    useEffect(() => {

        async function getVilles() {
            try {
              const response = await fetch(backend_url+'/villes');
              const data = await response.json();
              setVilles(data);
            } catch (error) {
              console.error(error);
            }
        }

        getVilles();

        let token = localStorage.getItem("token");
        if(token == undefined){
          setIsLoggedin(false)
        }else{
          setIsLoggedin(true)
        }
    
        
      }, []);

      function handleFormChange(){
        setDeactivateButton(true);
        let array = [];
        array.push(document.getElementById('input-depart').value);
        array.push(document.getElementById('input-arrivee').value);
        array.push(document.getElementById('kilos').value);
        array.push(document.getElementById('description').value);
        
        if(!isLoggedin){
            array.push(document.getElementById('email').value);
            array.push(document.getElementById('pseudo').value);
        }
        
        for (const element of array) {
            if(element === undefined ){
                return
            }
            if(element === "" ){
                return
            }
            console.log(array);
        }
        setDeactivateButton(false);

      }

      function addColisRequest(){
        let email = "";
        let pseudo = "";
        let headers = {};
        let endpoint = '/colis/add/public';
        

        if(!isLoggedin){
            email = document.getElementById('email').value;
            pseudo = document.getElementById('pseudo').value;
            headers = { 'Content-Type': 'application/json' }
        }else{
            let token = localStorage.getItem("token");
            endpoint = '/colis/add';
            headers = { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer '+token
         }
        }
        const depart = document.getElementById('input-depart').value;
        const arrivee = document.getElementById('input-arrivee').value;
        const kilos = document.getElementById('kilos').value;
        const description = document.getElementById('description').value;
        

        const requestOptions = {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ 
            ville_depart: depart,
            ville_arrivee: arrivee,
            kilos: kilos,
            visibilite: choixVisibilite,
            objet: objetDemande,
            pseudo: pseudo,
            telephone: '',
            description: description,
            email: email
          
          })};
        
        fetch(backend_url+endpoint, requestOptions)
        .then(response => {
            if(response.ok){
                toast.success("Demande bien enregistrée!");
                setTimeout(()=>{

                    navigate('/success', { state: { from: 'add-trajet', message: "Votre demande  de transport a été ajoutée avec succés!" } })

                },
            
                2000);
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            if(data.detail !== undefined){
                toast.error(data.detail);
            }
            
        })
      }
    
      function changeVisibilite(item){
        setChoixVisibilite(item.value);
      }

    return (
        <>
            <Navbar />
            <ToastContainer />
            <section className="py-12 w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 bg-slate-950/80 navbar-background"></div>

                <div className="container">
                    <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h6 className="mb-3 font-medium leading-normal text-xl mt-10 text-white">Un colis à envoyer ? Un achat à effectuer? Faites la demande ici</h6>

                    </div>
                </div>
                </div>
            </section>
            <section className="relative">
                <div className='container'>
                    <p className='description-about'>Vous  voulez envoyer un colis mais vous n'avez pas encore trouvé de transporteur,
                        postez votre demande ici. Vous serez alerté lorsqu'une trajet correpond à votre recherche.
                        Les transporteurs pourront également vous contacter par mail.
                    </p>
                </div>
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">Déposer une demande  !</h3>

                                    <form name="myForm" id="myForm" >
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid grid-cols-1">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="form-label font-medium">Ville de départ:</label>

                                                <Autocomplete
                                                    className='autocomplete'
                                                    disablePortal
                                                    fullWidth
                                                    id="input-depart"
                                                    onChange={handleFormChange}
                                                    options={villes}
                                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                                    renderInput={(params) => <TextField {...params}  variant="outlined"   style={{ backgroundColor: '#f6f1f8'  }} />}
                                                    />
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="form-label font-medium">Ville d'arrivee:</label>
                                                
                                                <Autocomplete
                                                    className='autocomplete'
                                                    disablePortal
                                                    id="input-arrivee"
                                                    fullWidth
                                                    onChange={handleFormChange}
                                                    options={villes}
                                                    getOptionLabel={(option) => option.emoji + " " + option.nom}
                                                    renderInput={(params) => <TextField {...params}  variant="outlined"   style={{ backgroundColor: '#f6f1f8'  }} />}
                                                    />
                                            </div>
                                        </div>

                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            {!isLoggedin &&
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="form-label font-medium">Email:</label>
                                                <input required name="email" id="email" type="email" onChange={handleFormChange} className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                            }
                                            {!isLoggedin &&
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="pseudo" className="form-label font-medium">Pseudo:</label>
                                                <input  required name="pseudo" id="pseudo" onChange={handleFormChange} type="text" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>
                                            }
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="kilos" className="form-label font-medium">Poids du colis:</label>
                                                <input onChange={handleFormChange} required type='number' name="kilos" id="kilos" className="input-wrapper w-full py-2 px-3 h-10 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder="" />
                                            </div>

                                            <div className="mb-5">
                                                <Select 
                                                    label="Objet de la demande"
                                                    placeholder="Objet de la demande"
                                                    inputId='objet-demande'
                                                    name='objet-demande'
                                                    onChange={changeObjetDemande}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                          ...baseStyles,
                                                          backgroundColor: '#f6f1f8'
                                                        }),
                                                    }}
                                                    options={options_type_demande} />
                                            </div>

                                            <div className="mb-5">
                                                <Select 
                                                    label="Qui peut voir ma demande"
                                                    placeholder="Qui peut voir ma demande"
                                                    inputId='visibilite-demande'
                                                    name='visibilite-demande'
                                                    onChange={changeVisibilite}
                                                    styles={{
                                                        control: (baseStyles) => ({
                                                          ...baseStyles,
                                                          backgroundColor: '#f6f1f8'
                                                        }),
                                                    }}
                                                    options={options} />
                                            </div>

                                            <div className="mb-5">
                                                <label htmlFor="description" className="form-label font-medium">Description:</label>
                                                <textarea onChange={handleFormChange} required name="description" id="description" className="input-wrapper w-full py-2 px-3 border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder=""></textarea>
                                            </div>
                                        </div>
                                        
                                    </form>
                                    <button disabled={deactivateButton} onClick={addColisRequest} id="submit" name="send" className="colismi-button w-full">Envoyer la demande</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
            <Footer />
        </>
    )
}