import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './dashboard.css';

const Dashboard = () => {
    const [activeUsers, setActiveUsers] = useState(0);
    const [usersByRole, setUsersByRole] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState("");
    const [newRole, setNewRole] = useState({ userId: '', roleId: '' });

    // Fetch active users
    useEffect(() => {
        axios.get('/api/dashboard/active_users')
            .then(response => setActiveUsers(response.data.active_users))
            .catch(error => console.error('Error fetching active users:', error));
    }, []);

    // Fetch roles for filtering
    useEffect(() => {
        axios.get('/api/roles')
            .then(response => setRoles(response.data))
            .catch(error => console.error('Error fetching roles:', error));
    }, []);

    // Fetch users by selected role
    const handleRoleChange = (roleId) => {
        setSelectedRole(roleId);
        axios.get(`/api/dashboard/users_by_role/${roleId}`)
            .then(response => setUsersByRole(response.data))
            .catch(error => console.error('Error fetching users by role:', error));
    };

    // Add role to user
    const handleAddRole = () => {
        axios.post('/api/dashboard/add_role_to_user', newRole)
            .then(response => alert(response.data.status))
            .catch(error => console.error('Error adding role to user:', error));
    };

    return (
        <div className="dashboard-container">
            <h1 className='title-h1'>Admin Dashboard</h1>
            <div className="dashboard-stats">
                <div className="stat-box">
                    <h2>Active Users</h2>
                    <p>{activeUsers}</p>
                </div>
            </div>

            <div className="role-filter">
                <h2>Filter Users by Role</h2>
                <select onChange={(e) => handleRoleChange(e.target.value)} value={selectedRole}>
                    <option value="">Select a role</option>
                    {roles.map(role => (
                        <option key={role.id} value={role.id}>{role.nom}</option>
                    ))}
                </select>

                {usersByRole.length > 0 && (
                    <div className="users-list">
                        <h3>Users with Role</h3>
                        <ul>
                            {usersByRole.map(user => (
                                <li key={user.id}>{user.pseudo} - {user.email}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>

            <div className="add-role">
                <h2>Add Role to User</h2>
                <input
                    type="number"
                    placeholder="User ID"
                    value={newRole.userId}
                    onChange={(e) => setNewRole({ ...newRole, userId: e.target.value })}
                />
                <input
                    type="number"
                    placeholder="Role ID"
                    value={newRole.roleId}
                    onChange={(e) => setNewRole({ ...newRole, roleId: e.target.value })}
                />
                <button  className='dashboard-button' onClick={handleAddRole}>Add Role</button>
            </div>
        </div>
    );
};

export default Dashboard;
