import React from 'react'
import Navbar from '../../../component/navbar'
import Footer from '../../../component/footer';
import { ToastContainer } from 'react-toastify';


export default function Payment() {
    const options = {
        // passing the client secret obtained from the server
        clientSecret: '{{CLIENT_SECRET}}',
      };
   
    return (
        <div>
            <Navbar />
            <ToastContainer />
            <section className="py-28-a w-full table relative bg-[url('../../assets/images/bg/bg-5.jpg')] bg-bottom bg-no-repeat" style={{backgroundSize:'cover'}} id="home">
                <div className="absolute inset-0 navbar-background"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">Paiement</h3>
                    </div>
                </div>
            </section>

            <section>
         
            </section>
           
           
            <Footer />
        </div>
    )
}